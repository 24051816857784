import React from "react"

import { Link } from "gatsby"

import Layoutde from "../../components/layoutde/layoutde"
import SEO from "../../components/seo"

import SwiperCore, {Autoplay} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/swiper.scss'
import "../index.scss"

import ImgFtrsLogoWhite from "../../images/ftrs-logo-white.svg"
import ImgClientLogo1 from "../../images/client-logo/cez.svg"
import ImgClientLogo2 from "../../images/client-logo/home-credit.svg"
import ImgClientLogo3 from "../../images/client-logo/koop.svg"
import ImgClientLogo4 from "../../images/client-logo/moneta.svg"
import ImgClientLogo5 from "../../images/client-logo/raiffeisenbank.svg"
import ImgClientLogo6 from "../../images/client-logo/airbank.svg"
import ImgClientLogo7 from "../../images/client-logo/t-mobile.svg"
import ImgClientLogo8 from "../../images/client-logo/uniqa.svg"
import ImgClientLogo9 from "../../images/client-logo/vodafone.svg"
import ImgClientLogo10 from "../../images/client-logo/toyota.svg"
import ImgClientLogo11 from "../../images/client-logo/o2.svg"
import ImgClientLogo12 from "../../images/client-logo/peugeot.svg"
import VideoBg from "../../videos/ftrs-video.mp4"

SwiperCore.use([Autoplay]);

const paramsHomeReference = {
  loop: true,
  speed: 1000,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
  grabCursor: true,
  keyboard: {
    enabled: true,
  },
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 3,
      slidesPerGroup: 3
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 3,
      slidesPerGroup: 3
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 4,
      slidesPerGroup: 4
    },
    // when window width is >= 640px
    1024: {
      slidesPerView: 6,
      slidesPerGroup: 6
    }
  }
}

const IndexPage = () => (

<div className="ftrs ftrs--index">

<Layoutde>

    <SEO title="Future Rockstars" description="Wir entwickeln digitale Produkte für Tausende Nutzer aus und in multinationalen Unternehmen verschiedenster Branchen." />

    <div className="ftrs-hero">

      <div className="ftrs-hero__content">
        <div className="ftrs-container ftrs-container--hero">
          <img
            src={ImgFtrsLogoWhite}
            className="ftrs-hero__logo-image"
            alt="Future Rockstars logo"
            data-sal="fade"
            data-sal-delay="300"
            data-sal-duration="2000"
          />
          <h1 data-sal="fade" data-sal-delay="400" data-sal-duration="2000" className="ftrs-hero__header">Gemeinsam schaffen wir moderne digitale Produkte</h1>
          <div data-sal="fade" data-sal-delay="600" data-sal-duration="2000" className="ftrs-hero__buttons">
            <Link className="ftrs-button ftrs-button--big mt-4" to="/de/was-wir-tun">
				Wie können wir Ihnen helfen?
            </Link>
          </div>
        </div>

        <div className="home-references" data-sal="fade" data-sal-delay="1000" data-sal-duration="2000">

          <Swiper  {...paramsHomeReference}>

            <SwiperSlide>
              <div className="home-references__item">
                <img className="home-references__image" src={ImgClientLogo1} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="home-references__item">
                <img className="home-references__image" src={ImgClientLogo2} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="home-references__item">
                <img className="home-references__image" src={ImgClientLogo3} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="home-references__item">
                <img className="home-references__image" src={ImgClientLogo4} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="home-references__item">
                <img className="home-references__image" src={ImgClientLogo5} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="home-references__item">
                <img className="home-references__image" src={ImgClientLogo6} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="home-references__item">
                <img className="home-references__image" src={ImgClientLogo7} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="home-references__item">
                <img className="home-references__image" src={ImgClientLogo8} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="home-references__item">
                <img className="home-references__image" src={ImgClientLogo9} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="home-references__item">
                <img className="home-references__image" src={ImgClientLogo10} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="home-references__item">
                <img className="home-references__image" src={ImgClientLogo11} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="home-references__item">
                <img className="home-references__image" src={ImgClientLogo12} alt="" />
              </div>
            </SwiperSlide>

          </Swiper>

        </div>

      </div>

      <video loop autoPlay="autoplay" muted playsInline className="ftrs-hero__video">
        <source media="(orientation: landscape)" src={VideoBg} type="video/mp4" />
      </video>

    </div>

</Layoutde>

</div>

)

export default IndexPage
